<template>
  <div class="library-test">
    <div class="d-flex logo-menu">
      <router-link
        :to="{
          name: $route.params.shopId ? 'store' : 'store domain',
        }"
        ><div class="logo">
          <img
            v-if="logoShop"
            :src="logoShop"
            width="100%"
            height="100%"
            style="
              object-fit: contain;
              width: 200px;
              height: 50px;
              object-position: left;
            "
          /><img
            v-else
            src="@/assets/img/logo_default.png"
            width="100%"
            height="100%"
            style="
              object-fit: contain;
              width: 200px;
              height: 50px;
              object-position: left;
            "
          />
        </div>
      </router-link>
      <div class="menu">
        <div class="header-listOption-menu" style="width: 100%">
          <VueSlickCarousel
            v-bind="settingsSlickHeaderMenu"
            v-if="dataMenu1.length > 0"
          >
            <template #nextArrow="arrowOption">
              <div class="custom-arrow">
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>
            <template #prevArrow="arrowOption">
              <div class="custom-arrow">
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>
            <div
              class="header-listOption-menu-item link-header position-relative"
              v-for="(item, index) in dataMenu1"
              :key="index"
            >
              <!-- <router-link
                :to="`${beginUrl}${item.url}`"
                class="d-flex flex-column align-items-center mt-2"
                :class="{ 'img-menu-header': isImage(item.icon) }"
              >
                <img v-if="isImage(item.icon)" :src="item.icon" alt="" />
                <b-icon v-else :icon="`${item.icon}`" />
                <span class="text-name">{{ item.page_title }}</span>
                <span
                  v-if="item.page_title == 'カート'"
                  class="header-listOption-menu-item-cart position-absolute"
                >
                  {{ countListCart }}
                </span>
                <span
                  v-if="item.page_title == 'ニュース' && countNewUnread > 0"
                  class="header-listOption-menu-news position-absolute"
                >
                </span>
              </router-link> -->
              <a
                :href="returnPage(item)"
                class="d-flex flex-column align-items-center mt-2"
                :class="{ 'img-menu-header': isImage(item.icon) }"
                :name="returnName(item)"
              >
                <img v-if="isImage(item.icon)" :src="item.icon" alt="" />
                <b-icon v-else :icon="`${item.icon}`" />
                <!-- <font-awesome-icon :icon="item.icon" /> -->
                <span class="text-name">{{ item.page_title }}</span>
                <span
                  v-if="item.page_title == 'カート'"
                  class="header-listOption-menu-item-cart position-absolute"
                >
                  {{ countListCart }}
                </span>
                <span
                  v-if="item.page_title == 'ニュース' && countNewUnread > 0"
                  class="header-listOption-menu-news position-absolute"
                >
                </span>
              </a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <div class="d-flex filter-tab">
      <div class="filter">
        <div class="header-search d-flex align-items-center">
          <b-form-input
            class="search-input"
            placeholder="商品名・目次で検索・著者名・カテゴリ名"
            style="border: 1px solid #939393"
            v-model="keyword"
            v-on:keyup.enter="searchContent"
          />
          <div class="icon-filter">
            <b-icon
              class="search-icon"
              icon="search"
              style="color: black"
              @click="searchContent()"
            />
          </div>
        </div>
      </div>
      <div class="header-tab d-flex align-items-end">
        <router-link
          :to="{ name: $route.params.shopId ? 'library' : 'library domain' }"
        >
          <div
            class="custom-tab d-flex align-items-center justify-content-center"
            style="
              height: 48.75px;
              border: 1px solid #d41876;
              border-bottom: none !important;
              position: relative;
              top: 2px;
              background: #fff;
            "
          >
            コンテンツ
          </div>
        </router-link>
        <router-link
          :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
        >
          <div
            class="custom-tab d-flex align-items-center justify-content-center"
            style="height: 48.75px"
          >
            ショップ
          </div>
        </router-link>
      </div>
      <div class="background-header"></div>
    </div>
    <div class="menu-filter">
      <VueSlickCarousel
        v-bind="settingsSlick"
        v-if="modalContent2.length > 0"
        style="margin: 0 auto"
      >
        <template #nextArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
        <div class="filter-carousel">
          <div class="btn btn-secondary" @click="showHideModal">絞り込み</div>
        </div>
        <div class="filter-carousel">
          <div class="btn btn-secondary">お気に入り</div>
        </div>
        <div class="filter-carousel">
          <div class="btn btn-secondary">最近追加</div>
        </div>
        <div class="filter-carousel">
          <div class="btn btn-secondary">最近閲覧</div>
        </div>
        <div
          class="filter-carousel"
          v-for="(value, index) in modalContent2"
          :key="index"
          @click="changeModalId(value, 2)"
        >
          <div class="btn btn-secondary">
            {{ value.text }}
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="content-library">
      <div>
        <b-row
          style="
            display: grid;
            grid-template-columns: auto auto auto;
            justify-content: space-between;
            justify-items: stretch;
          "
        >
          <b-col
            v-for="(value, index) in listLibrary"
            cols="12"
            md="4"
            :key="index"
            style="margin-bottom: 40px"
          >
            <div @click="goToDetailContent(value.id)" class="ct-home-card">
              <font-awesome-icon
                v-if="value.is_favorite"
                class="icon-favorite"
                :icon="['fas', 'bookmark']"
              ></font-awesome-icon>
              <div
                class="card-img"
                :class="{
                  'd-flex  align-items-center justify-content-center no-img':
                    !value.image_url,
                }"
                style="background: #0376bb; border-radius: inherit"
              >
                <img
                  v-if="value.image_url"
                  :src="`${urlBackend}/storage/${value.image_url}`"
                  alt=""
                />
                <div class="h5" v-else>
                  {{ value.title }}
                </div>
              </div>
              <div
                class="card-name px-3 pt-2 pb-2 font-title-content"
                style="
                  height: 34.5px;
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-weight: normal;
                "
              >
                {{ value.title }}
              </div>
              <div
                class="d-flex align-items-center pt-2 pb-2 px-3"
                style="height: 34.5px"
                @click="goToDetailAuthor(value.author_id)"
                v-on:click.stop
              >
                <div class="card-avatar" style="height: 30px; width: 30px">
                  <img
                    :src="
                      value.avatar
                        ? `${urlBackend}/${value.avatar}`
                        : image_url_default
                    "
                    alt=""
                  />
                </div>
                <div
                  class="card-nickName ml-2 text-dark"
                  style="font-size: 12px"
                >
                  {{ value.last_name + " " + value.first_name }}
                </div>
              </div>
              <div
                class="card-btn"
                style="
                  height: 34.5px;
                  align-items: center;
                  padding-right: 4.75px;
                "
              >
                <b-button
                  :class="value.is_favorite ? 'btn-danger' : 'btn-success'"
                  @click="addFavorites(value.id, index)"
                  v-on:click.stop
                  style="height: 25px; display: flex; align-items: center"
                >
                  <span v-if="value.is_favorite">お気に入り解除</span>
                  <span v-else>お気に入り追加</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- <div
      class="ct-home-filter"
      v-if="messageEmpty !== 'コンテンツはまだ所持していません'"
    >
      <div style="padding: 0 60px">
        <VueSlickCarousel
          v-bind="settingsSlick"
          v-if="modalContent2.length > 0"
          style="margin: 0 auto"
        >
          <template #nextArrow="arrowOption">
            <div class="custom-arrow">
              {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
            </div>
          </template>
          <template #prevArrow="arrowOption">
            <div class="custom-arrow">
              {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
            </div>
          </template>
          <div class="filter-carousel">
            <div class="btn btn-secondary" @click="showHideModal">
              <b-icon class="filter-icon mr-3" icon="search" />絞り込み
            </div>
          </div>
          <div class="filter-carousel">
            <div class="btn btn-secondary" @click="chooseFilterOut(dataLike)">
              お気に入り
            </div>
          </div>
          <div class="filter-carousel">
            <div
              class="btn btn-secondary"
              @click="chooseFilterOut(dataHistory)"
            >
              最近追加
            </div>
          </div>
          <div class="filter-carousel">
            <div class="btn btn-secondary" @click="chooseFilterOut(dataBuy)">
              最近閲覧
            </div>
          </div>
          <div
            class="filter-carousel"
            v-for="(value, index) in modalContent2"
            :key="index"
            @click="changeModalId(value, 2)"
          >
            <div class="btn btn-secondary">
              {{ value.text }}
            </div>
          </div>
        </VueSlickCarousel>
        <div class="ct-home-allOption d-flex flex-wrap align-items-center mt-4">
          <div
            v-for="(value, index) in filterSelect"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index"
          >
            <div class="allOption-content mr-2">{{ value.text }}</div>
            <div class="allOption-close" @click="removeFilter(index)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
          <div
            v-for="(value, index) in categoryDisplay"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index"
          >
            <div class="allOption-content mr-2">{{ value.text }}</div>
            <div class="allOption-close" @click="removeFilter(index)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
          <div
            v-for="(value, index) in authorDisplay"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index"
          >
            <div class="allOption-content mr-2">{{ value.text }}</div>
            <div class="allOption-close" @click="removeFilter(index)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
        </div>
        <div v-if="isOpenFilter" class="ct-home-modalFilter">
          <div
            class="position-fixed modalFilter-backdrop"
            @click="showHideModal"
          />
          <div class="position-fixed modalFilter-content">
            <div
              v-if="modalOpen > 1"
              class="modalFilter-return"
              @click="returnModal"
            >
              <b-icon icon="arrow-left" scale="1" />
            </div>
            <div class="modalFilter-close" @click="showHideModal">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
            <div class="modalFilter-header text-center">{{ modalTitle }}</div>
            <div style="max-height: 70vh; overflow: auto">
              <div
                v-for="(value, index) in modalContent"
                class="modalFilter-item position-relative"
                :key="index"
                @click="chooseFilter(value)"
              >
                <div>{{ value.text }}</div>
                <div
                  class="modalFilter-itemIcon position-absolute"
                  v-bind:class="{ 'modalFilter-check': !value.open }"
                >
                  <font-awesome-icon
                    v-if="value.open"
                    icon="fa-solid fa-angle-right"
                  />
                  <b-icon
                    v-else-if="
                      (modalOpen === 1 &&
                        filterSelect.find((item) => item.id === value.id)) ||
                      (modalOpen === 2 &&
                        categorySelect.find((item) => item.id === value.id)) ||
                      (modalOpen === 3 &&
                        authorSelect.find((item) => item.id === value.id))
                    "
                    icon="check"
                    scale="2"
                  />
                </div>
              </div>
              <div v-if="modalOpen === 1" class="modalFilter-search mt-3">
                <label>キーワード</label>
                <div class="ct-home-search d-flex align-items-center">
                  <b-form-input
                    class="search-input"
                    v-model="text"
                    placeholder="商品名・目次で検索・著者名・カテゴリ名"
                  />
                  <div class="search-icon" @click="chooseFilter({ text })">
                    <b-icon icon="search" />
                  </div>
                </div>
              </div>
              <div
                v-if="
                  modalOpen === 2 &&
                  listLibraryCategory.current_page <
                    listLibraryCategory.last_page
                "
                class="d-flex justify-content-center align-items-center pb-5"
              >
                <b-button class="btn-info" @click="seeMoreCategory"
                  >続きを見る</b-button
                >
              </div>
              <div
                v-if="
                  modalOpen === 3 &&
                  listLibraryAuthor.current_page < listLibraryAuthor.last_page
                "
                class="d-flex justify-content-center align-items-center pb-5"
              >
                <b-button class="btn-info" @click="seeMoreAuthor"
                  >続きを見る</b-button
                >
              </div>
              <div
                v-if="modalOpen === 2 || modalOpen === 3"
                class="pb-4 modalFilter-btn d-flex justify-content-center"
              >
                <b-button class="btn-info mt-3" @click="searchLibrary(null)"
                  >検索する</b-button
                >
              </div>
              <div
                v-else
                class="pb-4 modalFilter-btn d-flex justify-content-center"
              >
                <b-button class="btn-info mt-3" @click="searchLibrary({ text })"
                  >検索する</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ct-home-content desktop">
      <div>
        <b-row
          style="
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 30px;
            justify-content: space-around;
            justify-items: stretch;
          "
        >
          <b-col
            v-for="(value, index) in listLibrary"
            class="mb-5"
            cols="12"
            md="4"
            :key="index"
          >
            <div
              @click="goToDetailContent(value.id)"
              class="ct-home-card"
              style="
                margin: 0 auto;
                box-shadow: -5px -5px 10px #a3abaf, 5px 5px 10px #a3abaf;
              "
            >
              <font-awesome-icon
                v-if="value.is_favorite"
                class="icon-favorite"
                :icon="['fas', 'bookmark']"
              ></font-awesome-icon>
              <div
                class="card-img"
                :class="{
                  'd-flex  align-items-center justify-content-center no-img':
                    !value.image_url,
                }"
              >
                <img
                  v-if="value.image_url"
                  :src="`${urlBackend}/storage/${value.image_url}`"
                  alt=""
                />
                <div class="h5 text-dark" v-else>
                  {{ value.title }}
                </div>
              </div>
              <div class="card-name px-3 pt-3 pb-3 font-title-content">
                {{ value.title }}
              </div>
              <div
                class="d-flex align-items-center pb-4 px-3"
                @click="goToDetailAuthor(value.author_id)"
                v-on:click.stop
              >
                <div class="card-avatar">
                  <img
                    :src="
                      value.avatar
                        ? `${urlBackend}/${value.avatar}`
                        : image_url_default
                    "
                    alt=""
                  />
                </div>
                <div class="card-nickName ml-2 text-dark">
                  {{ value.last_name + " " + value.first_name }}
                </div>
              </div>
              <div class="card-btn pb-2">
                <b-button
                  :class="value.is_favorite ? 'btn-danger' : 'btn-success'"
                  @click="addFavorites(value.id, index)"
                  v-on:click.stop
                  style="margin-right: 10px"
                >
                  <span v-if="value.is_favorite">お気に入り解除</span>
                  <span v-else>お気に入り追加</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="container mt-3">
        {{ messageEmpty }}
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center pb-5 desktop">
      <b-button
        class="btn-info"
        v-if="
          dataLibraries
            ? dataLibraries.data.last_page !== dataLibraries.data.current_page
            : dataLibrary.data
            ? dataLibrary.data.last_page !== dataLibrary.data.current_page
            : ''
        "
        @click="seeMore"
        >続きを見る</b-button
      >
    </div>
    <div class="ct-home-content mobile">
      <div class="container">
        <b-row>
          <b-col
            v-for="(value, index) in listLibrary"
            cols="12"
            md="3"
            :key="index"
          >
            <b-row style="border-bottom: 1px solid #e6e6e6">
              <b-col cols="4">
                <div @click="goToDetailContent(value.id)" class="ct-home-card">
                  <font-awesome-icon
                    v-if="value.is_favorite"
                    class="icon-favorite"
                    :icon="['fas', 'bookmark']"
                  ></font-awesome-icon>
                  <div
                    class="card-img"
                    :class="{
                      'd-flex  align-items-center justify-content-center no-img':
                        !value.image_url,
                    }"
                  >
                    <img
                      v-if="value.image_url"
                      :src="`${urlBackend}/storage/${value.image_url}`"
                      alt=""
                    />
                    <div class="h5 text-dark" v-else>
                      {{ value.title }}
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="7">
                <div @click="goToDetailContent(value.id)" class="ct-home-card">
                  <div class="card-name pl-3 pt-1 pb-1 font-title-content">
                    {{ value.title }}
                  </div>
                  <div
                    class="d-flex align-items-center pb-3 px-3 card-author"
                    @click="goToDetailAuthor(value.author_id)"
                    v-on:click.stop
                  >
                    <div class="card-avatar">
                      <img
                        :src="
                          value.avatar
                            ? `${urlBackend}/${value.avatar}`
                            : image_url_default
                        "
                        alt=""
                      />
                    </div>
                    <div class="card-nickName ml-2 text-dark">
                      {{ value.last_name + " " + value.first_name }}
                    </div>
                  </div>
                  <div class="card-btn">
                    <b-button
                      :class="value.is_favorite ? 'btn-danger' : 'btn-success'"
                      @click="addFavorites(value.id, index)"
                      v-on:click.stop
                    >
                      <span v-if="value.is_favorite">お気に入り解除</span>
                      <span v-else>お気に入り追加</span>
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col cols="1">
                <font-awesome-icon
                  class="icon-right"
                  icon="fa-solid fa-angle-right"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div class="container mt-3">
        {{ messageEmpty }}
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center pb-5 mobile">
      <b-button
        class="btn-info"
        v-if="
          dataLibraries
            ? dataLibraries.data.last_page !== dataLibraries.data.current_page
            : dataLibrary.data
            ? dataLibrary.data.last_page !== dataLibrary.data.current_page
            : ''
        "
        @click="seeMore"
        >続きを見る</b-button
      >
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import { VueEditor } from "vue2-editor";
import image_url_default from "@/assets/img/content-default.png";
// import PopUp from "@/views/default/PopUp";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import icon_menu from "@/assets/img/iconmenu.png";
import { Routes } from "../../utils/routes";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
export default {
  name: "Library",
  components: {
    // PopUp,
    VueSlickCarousel,
  },
  data() {
    return {
      icon_menu: icon_menu,
      urlBackend: process.env.VUE_APP_ROOT_BACKEND,
      test: `<p><span style="background-color: rgb(0, 0, 0);text-align: center;">abc</span></p>`,
      image_url_default,
      text: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      isOpenFilter: false,
      modalOpen: 1,
      modalTitle: "絞り込み検索",
      modalContent: [],
      dataLike: {
        text: "お気に入り追加済",
        open: false,
        id: "favorite",
      },
      dataHistory: {
        text: "最近学習したコンテンツ",
        open: false,
        id: "view",
      },
      dataBuy: {
        text: "最近閲覧",
        open: false,
        id: "buy",
      },
      modalContent1: [
        {
          text: "カテゴリ",
          open: true,
          openId: 2,
        },
        {
          text: "著者名",
          open: true,
          openId: 3,
        },
        {
          text: "お気に入り追加済",
          open: false,
          id: "favorite",
        },
        {
          text: "最近学習したコンテンツ",
          open: false,
          id: "view",
        },
        {
          text: "最近購入したコンテンツ",
          open: false,
          id: "purchase",
        },
      ],
      modalContent2: [],
      modalContent3: [],
      formdata: {
        keyword: "",
        author_id: [],
        category_id: [],
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        is_search_favorite: "",
        is_search_recent_content: "",
        is_search_new_content: "",
        limit: 8,
        page: 1,
      },
      filterSelect: [],
      authorSelect: [],
      categorySelect: [],
      authorDisplay: [],
      categoryDisplay: [],
      isShowModal: true,
      dataLibraries: null,
      isFirstCall: false,
      messageEmpty: "",
      listLibrary: [],
      isSeeMore: false,
      indexSelectFavorite: null,
      listCategory: [],
      listAuthor: [],
      formCategory: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 1,
      },
      formAuthor: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 1,
      },
      settingsSlick: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        // variableWidth: true,
        // centerMode: true,
      },
      settingsSlickHeaderMenu: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
      },
      dataMenu1: [],
      beginUrl: "/" + Routes.SHOP + "/" + this.$route.params.shopId,
      countListCart: 0,
      logoShop: null,
      inquiry_URL: "",
      keyword: "",
    };
  },
  computed: {
    ...mapGetters({
      dataLibrary: "dataLibrary",
      listLibraryAuthor: "listLibraryAuthor",
      listLibraryCategory: "listLibraryCategory",
      success: "success",
      error: "error",
      message: "message",
      listSettingPageHeader: "listSettingPageHeader",
      ListSettingShop: "ListSettingShop",
      listCart: "listCart",
      countNewUnread: "countNewUnread",
    }),
  },
  watch: {
    ListSettingShop() {
      const dataShop = this.ListSettingShop;
      if (this.ListSettingShop.length > 0) {
        if (dataShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND + "/" + dataShop[0].logo;
        }
        this.inquiry_URL = dataShop[0].url_qa;
      }
    },
    listCart() {
      this.countListCart = this.listCart.length;
      // this.listCart.forEach((element) => {
      //   element.list_cart.forEach(() => {
      //     this.countListCart += 1;
      //   });
      // });
    },
    listSettingPageHeader() {
      this.dataMenu1 = this.listSettingPageHeader;
    },
    listLibraryAuthor() {
      this.listAuthor = this.listLibraryAuthor.data.map((item) => {
        return {
          text: item.last_name + " " + item.first_name,
          open: false,
          id: item.user_id,
          kind: "author",
        };
      });
      this.listAuthor.forEach((element) => {
        this.modalContent3.push(element);
      });
    },
    listLibraryCategory() {
      this.listCategory = this.listLibraryCategory.data.map((item) => {
        return {
          text: item.category_name,
          open: false,
          id: item.id,
          kind: "category",
        };
      });
      this.listCategory.forEach((element) => {
        this.modalContent2.push(element);
      });
    },
    dataLibrary() {
      this.modalOpen = 1;
      if (this.dataLibrary.data && this.dataLibrary.data.data.length > 0) {
        if (this.isSeeMore) {
          this.dataLibrary.data.data.forEach((element) => {
            this.listLibrary.push(element);
          });
        } else {
          this.listLibrary = this.dataLibrary.data.data;
        }
        this.messageEmpty = "";
        this.isFirstCall = true;
      } else if (!this.isFirstCall && !this.$route.params.keyword) {
        this.messageEmpty = "コンテンツはまだ所持していません";
        this.isFirstCall = true;
      } else {
        this.listLibrary = [];
        this.messageEmpty = "該当するコンテンツは見つかりませんでした。";
      }
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        if (
          this.filterSelect.length > 0 &&
          this.filterSelect[0].id === "favorite"
        ) {
          this.isSeeMore = false;
          this.getDataLibrary(this.formdata);
        } else {
          if (this.listLibrary.length > 0) {
            this.listLibrary[this.indexSelectFavorite].is_favorite =
              !this.listLibrary[this.indexSelectFavorite].is_favorite;
          }
        }
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  created() {
    if (this.$route.params.keyword) {
      const value = { text: this.$route.params.keyword };
      this.formdata = {
        keyword: this.$route.params.keyword,
        author_id: [],
        category_id: [],
        shop_id: this.shop_id,
        is_search_favorite: "",
        is_search_recent_content: "",
        is_search_new_content: "",
        limit: 8,
        page: 1,
      };
      this.filterSelect.push(value);
      this.isSeeMore = false;
      this.getDataLibrary(this.formdata);
    } else {
      this.isSeeMore = false;
      this.getDataLibrary(this.formdata);
    }
    this.getListLibraryAuthor(this.formAuthor);
    this.getListLibraryCategory(this.formCategory);
    this.getListSettingPageHeader(this.shop_id);
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
    });
    this.getListCart({
      shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
    });
    const request = {
      shop_id: this.shop_id,
    };
    this.getUnreadNew(request);
  },
  // mounted() {
  //   let container = document.getElementsByClassName("ql-toolbar")[0];
  //   let item = document.createElement("span");
  //   item.classList.add("ql-formats");
  //   let input = document.createElement("button");
  //   input.setAttribute("type", "button");
  //   input.classList.add("ql-test");
  //   input.innerHTML = "test";
  //   item.appendChild(input);
  //   container.appendChild(item);
  // },
  methods: {
    ...mapActions({
      addFavorite: "addFavorite",
      getDataLibrary: "getDataLibrary",
      getListLibraryAuthor: "getListLibraryAuthor",
      getListLibraryCategory: "getListLibraryCategory",
      getListSettingPageHeader: "getListSettingPageHeader",
      getListSettingShopUser: "getListSettingShopUser",
      getListCart: "getListCart",
      getUnreadNew: "getUnreadNew",
    }),
    isImage(url) {
      return /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(url);
    },
    toggleSidebar() {
      this.isActive = !this.isActive;
      this.$emit("toggleSidebar", this.isActive);
    },
    showHideModal() {
      this.isOpenFilter = !this.isOpenFilter;
      if (this.isOpenFilter) {
        this.modalContent = this.modalContent1;
        this.modalTitle = "絞り込み検索";
      }
      this.modalOpen = 1;
    },
    returnModal() {
      this.modalOpen = 1;
      this.modalContent = this.modalContent1;
      this.modalTitle = "絞り込み検索";
    },
    chooseFilter(value) {
      this.dataLibraries = null;
      this.text = "";
      if (this.modalOpen === 1) {
        if (value.open) {
          this.modalContent = this["modalContent" + value.openId];
          this.modalOpen = value.openId;
          this.modalTitle = value.text;
        } else {
          this.formdata = {
            keyword: "",
            author_id: [],
            category_id: [],
            shop_id: this.shop_id,
            is_search_favorite: "",
            is_search_recent_content: "",
            is_search_new_content: "",
            limit: 8,
            page: 1,
          };
          this.authorSelect = [];
          this.categorySelect = [];
          this.authorDisplay = [];
          this.categoryDisplay = [];
          if (value.id === "favorite") {
            this.formdata.is_search_favorite = true;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          } else if (value.id === "view") {
            this.formdata.is_search_recent_content = true;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          } else if (value.id === "purchase") {
            this.formdata.is_search_new_content = true;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          } else if (!value.id) {
            this.formdata.keyword = value.text;
            this.isSeeMore = false;
            this.getDataLibrary(this.formdata);
          }
          this.filterSelect = [];
          this.filterSelect.push(value);
          this.isOpenFilter = false;
          this.modalOpen = 0;
        }
      } else {
        if (this.modalOpen === 2) {
          if (this.categorySelect.find((item) => item.id === value.id)) {
            this.formdata.category_id.splice(
              this.formdata.category_id.indexOf(value.id),
              1
            );
            this.categorySelect.splice(this.categorySelect.indexOf(value), 1);
          } else {
            this.formdata.category_id.push(value.id);
            this.categorySelect.push(value);
          }
        } else if (this.modalOpen === 3) {
          if (this.authorSelect.find((item) => item.id === value.id)) {
            this.formdata.author_id.splice(
              this.formdata.author_id.indexOf(value.id),
              1
            );
            this.authorSelect.splice(this.authorSelect.indexOf(value), 1);
          } else {
            this.formdata.author_id.push(value.id);
            this.authorSelect.push(value);
          }
        }
      }
    },
    searchLibrary(value) {
      if (value) {
        this.formdata.keyword = value.text;
        this.filterSelect = [];
        this.filterSelect.push(value);
      } else {
        this.formdata.keyword = "";
        this.filterSelect = [];
      }
      this.formdata.is_search_favorite = "";
      this.formdata.is_search_recent_content = "";
      this.formdata.is_search_new_content = "";
      this.authorDisplay = [];
      this.categoryDisplay = [];
      this.authorSelect.forEach((element) => {
        this.authorDisplay.push(element);
      });
      this.categorySelect.forEach((element) => {
        this.categoryDisplay.push(element);
      });
      this.isSeeMore = false;
      this.formdata.page = 1;
      this.isOpenFilter = false;
      this.getDataLibrary(this.formdata);
      this.modalOpen = 0;
    },
    removeFilter(index) {
      this.dataLibraries = null;
      if (this.filterSelect.length > 0) {
        this.filterSelect.splice(index, 1);
      }
      if (this.categoryDisplay.length > 0) {
        this.categoryDisplay.splice(index, 1);
        this.formdata.category_id.splice(index, 1);
        this.categorySelect.splice(index, 1);
      }
      if (this.authorDisplay.length > 0) {
        this.authorDisplay.splice(index, 1);
        this.formdata.author_id.splice(index, 1);
        this.authorSelect.splice(index, 1);
      }
      if (
        this.filterSelect.length === 0 &&
        this.categoryDisplay.length === 0 &&
        this.authorDisplay.length === 0
      ) {
        this.formdata = {
          keyword: "",
          author_id: [],
          category_id: [],
          shop_id: this.shop_id,
          is_search_favorite: "",
          is_search_recent_content: "",
          is_search_new_content: "",
          limit: 8,
          page: 1,
        };
      }
      this.formdata.page = 1;
      this.isSeeMore = false;
      this.getDataLibrary(this.formdata);
    },
    chooseFilterOut(value) {
      this.dataLibraries = null;
      this.formdata = {
        keyword: "",
        author_id: [],
        category_id: [],
        shop_id: this.shop_id,
        is_search_favorite: "",
        is_search_recent_content: "",
        is_search_new_content: "",
        limit: 8,
        page: 1,
      };
      this.authorSelect = [];
      this.categorySelect = [];
      this.authorDisplay = [];
      this.categoryDisplay = [];
      if (value.id === "favorite") {
        this.formdata.is_search_favorite = true;
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      } else if (value.id === "view") {
        this.formdata.is_search_recent_content = true;
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      } else if (value.id === "buy") {
        this.formdata.is_search_new_content = true;
        this.isSeeMore = false;
        this.getDataLibrary(this.formdata);
      }
      this.filterSelect = [value];
    },
    seeMore() {
      this.formdata.page += 1;
      this.isSeeMore = true;
      this.getDataLibrary(this.formdata);
    },
    seeMoreCategory() {
      this.formCategory.page += 1;
      this.getListLibraryCategory(this.formCategory);
    },
    seeMoreAuthor() {
      this.formAuthor.page += 1;
      this.getListLibraryAuthor(this.formCategory);
    },
    addFavorites(content_id, index) {
      this.indexSelectFavorite = index;
      this.addFavorite({ content_id: content_id, shop_id: this.shop_id });
    },
    goToDetailAuthor(author_id) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "author detail",
          params: {
            shopId: this.shop_id,
            id: author_id,
          },
        });
      } else {
        this.$router.push({
          name: "author detail domain",
          params: {
            id: author_id,
          },
        });
      }
    },
    goToDetailContent(content_id) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "DetailContentLibrary",
          params: {
            shopId: this.shop_id,
            idContent: content_id,
          },
          query: { keyword: this.formdata.keyword },
        });
      } else {
        this.$router.push({
          name: "DetailContentLibrary domain",
          params: {
            idContent: content_id,
          },
          query: { keyword: this.formdata.keyword },
        });
      }
    },
    changeModalId(value, id) {
      this.modalOpen = id;
      this.chooseFilter(value);
      this.searchLibrary(null);
    },
    returnPage(item) {
      if (item && item.type && item.type == 5) {
        return this.$router.resolve({
          name: "pageColumn",
          params: { id: item.fixed_page_id },
        }).href;
      } else {
        if (item.page_title == "お問い合わせ" && this.inquiry_URL) {
          return this.inquiry_URL;
        } else {
          return this.beginUrl + item.url;
        }
      }
    },
    returnName(item) {
      if (item.fixed_page_id) {
        return "menu_fixed_" + item.fixed_page_id + "_link";
      } else {
        const name = item.url.replace("/", "_");
        return "menu" + name + "_link";
      }
    },
    searchContent() {
      if (this.keyword) {
        const self = this;
        const formData = {
          keyword: this.keyword,
          author_id: "",
          category_id: "",
          shop_id: this.shop_id,
          is_search_favorite: "",
          is_search_recent_content: "",
          is_search_new_content: "",
          limit: "8",
        };
        const api = localStorage.getItem(Constants.TOKEN_USER)
          ? Api.userRequestServer
          : Api.adminRequestServer;
        api
          .post(`/${Urls.LIBRARY}/${Urls.FILTER}`, formData)
          .then((response) => {
            const { data } = response;
            const keyword = self.keyword;
            if (data.success) {
              if (self.$route.params.shopId) {
                self.$router
                  .push({
                    name: "library",
                    params: {
                      shopId: self.shop_id,
                    },
                    query: {
                      keyword: self.keyword,
                    },
                  })
                  .catch(() => {
                    self.$router
                      .push({
                        name: "store",
                      })
                      .then(() => {
                        self.$router.push({
                          name: "library",
                          params: {
                            shopId: self.shop_id,
                          },
                          query: {
                            keyword: keyword,
                          },
                        });
                      });
                  });
                self.keyword = "";
              } else {
                self.$router
                  .push({
                    name: "library domain",
                    query: {
                      keyword: self.keyword,
                    },
                  })
                  .catch(() => {
                    self.$router
                      .push({
                        name: "store domain",
                      })
                      .then(() => {
                        self.$router.push({
                          name: "library domain",
                          query: {
                            keyword: keyword,
                          },
                        });
                      });
                  });
                self.keyword = "";
              }
            }
          })
          .catch((error) => {
            this.$toasted.error(error.message);
          });
      }
    },
    // hideModalPop(e) {
    //   this.isShowModal = e;
    // },
  },
};
</script>

<style lang="scss" scoped>
.ct-home {
  &-tabs {
    height: 100px;
    background: #d5d5d5;
  }
  &-search {
    position: relative;
    width: 400px;
    height: 100%;
    input {
      width: 100%;
    }
    .search {
      &-input {
        padding-right: 35px;
      }
      &-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  &-tab {
    .custom-tab {
      width: 120px;
      height: 60px;
      border-radius: 10px 10px 0 0;
      &.active {
        background: #ebedef;
      }
    }
  }
  &-filter {
    padding: 30px 0;
    .filter-btn {
      button {
        padding: 10px 20px;
        border-radius: 10px;
        @media (max-width: 767px) {
          padding: 0 10px;
        }
      }
    }
    .filter-icon {
      font-size: 24px;
    }
  }
  &-link {
    font-size: 18px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    @media (max-width: 767px) {
      font-size: 12px;
      margin: 0 10px !important;
    }
  }
  &-allOption {
    // height: 98px;
    .allOption {
      &-close {
        width: 25px;
        height: 25px;
        background: rgb(110, 110, 110);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }
    }
  }
  &-modalFilter {
    .modalFilter {
      &-backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
        background-color: #0000008f;
        z-index: 10;
      }
      &-content {
        top: 50%;
        left: 50%;
        max-width: calc(100% - 20px);
        margin: auto;
        transform: translate(-50%, -50%);
        background: #fff;
        z-index: 11;
        border: 1px solid #b5b5b5;
        border-radius: 10px;
        @media (max-width: 767px) {
          top: 40%;
        }
      }
      &-header {
        padding: 25px 0;
        border-bottom: 1px solid #b5b5b5;
        font-size: 20px;
      }
      &-item {
        padding: 25px 20px;
        width: 700px;
        max-width: 100%;
        cursor: pointer;
        &:hover {
          background: #e3e3e3;
        }
      }
      &-search {
        padding: 20px;
        padding-top: 0;
        .ct-home-search {
          width: 100%;
          input {
            height: 50px;
          }
          .search-icon {
            right: 10px;
            font-size: 20px;
          }
        }
      }
      &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &-return {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &-itemIcon {
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        font-size: 20px;
      }
      &-check {
        color: #46c000;
      }
      &-success {
        color: #fff;
        background: #d3421e;
        border: unset;
      }
      &-btn {
        button {
          width: calc((100% - 20px) / 2);
          height: 40px;
        }
      }
    }
  }
  &-card {
    position: relative;
    margin: 0px auto;
    height: 353.6px;
    box-shadow: -1px -1px 10px rgb(163, 171, 175),
      1px 1px 10px rgb(163, 171, 175);
    .icon-favorite {
      position: absolute;
      left: 0;
      top: 0;
      color: red;
      height: 2rem;
    }
    @media (min-width: 768px) {
      border-bottom: 1px solid #e6e6e6;
    }
    a:hover {
      text-decoration: none;
    }
    width: 250px;
    .card {
      &-img {
        background-color: #dee9f0;
        height: 250px;
        width: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        @media (max-width: 767px) {
          width: 130px;
          height: 130px;
        }
      }
      &-avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-btn {
        display: flex;
        justify-content: right;
      }
    }
    @media (max-width: 767px) {
      left: -15px;
    }
  }
}
.ct-home-card:hover {
  @media (min-width: 768px) {
    cursor: pointer;
    background: #accee8;
    opacity: 0.8;
    border-radius: 3px;
    transition: all 0.5s ease;
    box-shadow: -5px -5px 10px #a3abaf, 5px 5px 10px #a3abaf;
    border: 1px solid #8ec3e8;
  }
}
.ct-home-card:hover .card-img {
  @media (min-width: 768px) {
    background: #accee8;
    opacity: 0.8;
    transition: all 0.5s ease;
  }
}
.slick-next {
  right: 0;
  z-index: 1;
}
.slick-prev {
  left: 0;
  z-index: 1;
}
.slick-next::before {
  color: black !important;
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  display: inline-block;
  font-weight: 600;
}
.slick-prev::before {
  color: black !important;
  font-family: "Font Awesome 5 Free";
  content: "\f053";
  display: inline-block;
  font-weight: 600;
}
.filter-carousel {
  height: 125px;
  position: relative;
  div {
    display: table-cell;
    height: 40px;
    min-width: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f0f0f0;
    color: black;
    border-radius: inherit;
    border: none;
  }
}
.library-test {
  width: 853.6px;
  margin: 0 auto;
  .logo-menu {
    height: 62.5px;
    .logo {
      width: 250px;
    }
    .menu {
      width: 606px;
    }
  }
  .filter-tab {
    height: 62.5px;
    position: relative;
    .filter {
      width: 360px;
      z-index: 2;
    }
    .header-tab {
      height: auto;
      align-self: center;
      margin-left: 50px;
      z-index: 2;
      position: absolute;
      bottom: 2px;
      left: 360px;
    }
    .background-header {
      height: 62.5px;
      background-color: #f0f0f0;
      width: 1000vw;
      position: absolute;
      left: -1000px;
      z-index: 1;
      border-bottom: 1px solid #d41876;
    }
  }
  .menu-filter {
    height: 125px;
  }
}
.icon-filter {
  width: 30px;
  height: 35px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background: #f29f05;
}
</style>
